/* src/styles/typewriter.css */
@keyframes typewriter {
  from {
    opacity: 0; /* Start with opacity 0 for fade-in effect */
  }

  to {
    opacity: 1; /* Fade in the text */
  }
}

.typewriter {
  opacity: 0; /* Initially hide the text */
  white-space: nowrap;
  animation: typewriter 1.5s ease-in-out forwards; /* Use ease-in-out for smooth fade-in */
}

/* Media query for smaller screens */
@media only screen and (max-width: 768px) {
  .typewriter {
    font-size: 16px; /* Adjust font size for smaller screens */
  }
}
