/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    @apply bg-black text-white;
}

.model-initial {
    animation: come-from-below 4s ease-in-out forwards;
}

@keyframes come-from-below {
    from {
        transform: translateY(-100%);
        /* Adjust for starting position */
    }

    to {
        transform: translateY(0);
    }
}